import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";

var pageTitle = 'Your Information Is Safe and Secure'
var pageDesc = 'The security of your information is very important to us. See below for information about our refund policy, privacy statement, and security.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="security" />
      <meta name="description" content={pageDesc} />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={pageTitle} />
      <meta itemprop="description" content={pageDesc} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesc} />

      {/* Open Graph data */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section style={{padding: '50px 0',}}>
      <article className="fixed">
        <br /><br /><br />
        <div className="wrap samll">
          <h1>{pageTitle}</h1>
          <p>The security of your information is very important to us. See below for information about our refund policy, privacy statement, and security.</p>
          <h3>Refund Policy</h3>
          <p>If you believe that an error has been made in connection with your online donation, contact us at <a href="mailto:giving@fullerton.edu">giving@fullerton.edu</a>. We will endeavor to work with you to correct any such error, and if necessary, make any refund or correction that is appropriate.</p>
          <h3>Privacy Statement</h3>
          <p>We take data security and the privacy of your personal information very seriously. California law mandates that State agencies shall not create personal records unless the need for the information is clearly established and that personal records shall be relevant to the purposes for which they are collected, be accurate and current, and not be obtained by fraudulent means.</p>
          <p>State officials who request personal data from citizens must notify the citizens regarding the purposes for which the information is collected; consequences to the citizen for refusing to provide the personal information; the citizen's right to inspect and correct personal records; whether the information is generally available for public inspection; and whether the information is made available to other entities.</p>
          <p>Cal State Fullerton Philanthropic Foundation is committed to providing you with web sites over which you may securely and confidently transact business.</p>
          <h3>How Secure Is Information About Me?</h3>
          <p>Cal State Fullerton Philanthropic Foundation is committed to protecting the security of your information. We do not save any billing information, including your billing address and credit card information.</p>
          <p>All billing information is directed to the credit card processing merchant using Secure Sockets Layer (SSL) software, which encrypts all of the information you input. It is important for you to protect against unauthorized access to your password and to your computer.</p>
        </div>
      </article>
    </section>
  </Layout>
  </React.Fragment>
);
